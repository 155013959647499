@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes bookmark {
  0%, 100% {
      border: 0px;
  }
  50% {
      border: 5px solid var(--primary-color);
  }
}