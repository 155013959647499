.bg-dark .toast-success {
    background-color: var(--toast-success-bg-color); 
}
  
.bg-dark .toast-error {
    background-color: var(--toast-error-bg-color);
}
  
.bg-dark .toast-info {
    background-color: var(--toast-info-bg-color);
}
  
.bg-dark .toast-warning {
    background-color: var(--toast-warning-bg-color);
}