.form-check-input {
    background-color: var(--checkbox-bg-color);
    border-color: var(--checkbox-border-color);
    cursor: pointer;

    &:checked {
        background-color: var(--checkbox-checked-bg-color);
        border-color: var(--checkbox-checked-bg-color);
    }

    &:hover {
        border-color: var(--checkbox-focus-border-color);
    }

    &:focus {
        border-color: var(--checkbox-focus-border-color);
        box-shadow: 0 0 0 0.25rem var(--checkbox-focus-boxshadow-color);
    }
}

