.progress {
    background-color: var(--progress-bg-color);
}

.progress-bar {
  background-color: var(--progress-bar-color);
}

.progress-bar.text-bg-success {
    background-color: var(--progress-bar-color) !important;
}

.progress-bar-striped {
    background-image: var(--progress-striped-animated-color);
    background-color: unset;
}

// Bootstrap sliders:
.form-range::-webkit-slider-thumb {
  background-color: var(--primary-color);
}
